import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule, DecimalPipe} from '@angular/common';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TranslateModule} from '@ngx-translate/core';

import {HomeRoutingModule} from './home-routing.module';
import {HomeComponent} from './home.component';
import {FormsModule} from '@angular/forms';
import {SessionsListComponent} from './sessions-list/sessions-list.component';
import {SharedModule} from '@app/shared/shared.module';
import {CoreModule} from '@app/core/core.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SessionComponent} from './session/session.component';
import {WaitingRoomComponent} from '@app/home/session/waiting-room/waiting-room.component';
import {PresentationComponent} from '@app/home/session/presentation/presentation.component';
import {OpentokService} from '@app/home/session/conference/opentok.service';
import {ConferenceComponent} from '@app/home/session/conference/conference.component';
import {ChatComponent} from '@app/home/session/chat/chat.component';
import {SearchSessionFilterPipe} from '@app/home/sessions-list/sessions-list-filters.component';
import {PollComponent} from '@app/home/session/poll/poll.component';
import {BooleanQuestionComponent} from '@app/home/session/poll/questionType/boolean/boolean.question.component';
import {RateQuestionComponent} from '@app/home/session/poll/questionType/rate/rate.question.component';
import {NumericQuestionComponent} from '@app/home/session/poll/questionType/numeric/numeric.question.component';
import {IntervalQuestionComponent} from '@app/home/session/poll/questionType/interval/interval.question.component';
import {PresentationActionBarComponent} from './session/presentation/action-bar/action-bar.component';
import {PresentationEventsService} from '@app/home/session/presentation/presentation-events.service';
import {RelatedContentComponent} from './session/presentation/related-content/related-content.component';
import {CommentBoxComponent} from './session/comment-box/comment-box.component';
import {PresenterControlComponent} from '@app/home/session/presentation/presenter-control/presenter-control.component';
import {DrawerComponent} from './session/presentation/drawer/drawer.component';
import {CollapseModule, BsDropdownModule, AccordionModule, TabsModule } from 'ngx-bootstrap';
import {SimpleNotificationsModule} from 'angular2-notifications';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        DragDropModule,
        TranslateModule,
        CoreModule,
        FormsModule,
        SharedModule,
        HomeRoutingModule,
        NgbModule,
        CollapseModule,
        BsDropdownModule,
        AccordionModule.forRoot(),
        TabsModule.forRoot(),
        SimpleNotificationsModule.forRoot()
    ],
    declarations: [
        HomeComponent,
        SessionsListComponent,
        WaitingRoomComponent,
        PresentationComponent,
        ChatComponent,
        SessionComponent,
        ConferenceComponent,
        SearchSessionFilterPipe,
        PollComponent,
        BooleanQuestionComponent,
        RateQuestionComponent,
        NumericQuestionComponent,
        IntervalQuestionComponent,
        PresentationActionBarComponent,
        RelatedContentComponent,
        CommentBoxComponent,
        PresenterControlComponent,
        DrawerComponent
    ],
    providers: [
        OpentokService,
        PresentationEventsService,
        DecimalPipe
    ]
})
export class HomeModule {
}
