import {Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {ChatMessage} from '@app/core/messaging/chat-message';
import {MessageInterface} from '@app/core/messaging/message';
import {MessagingService} from '@app/core/messaging/messaging.service';
import {Subscription} from 'rxjs';
import {
    PresentationEvent,
    PresentationEventsService,
    PresentationResponse
} from '@app/home/session/presentation/presentation-events.service';
import {OpentokService} from '@app/home/session/conference/opentok.service';
import {FlashService} from '@app/shared/flash/flash.service';
import {Session, SessionAudioType, SessionType} from '@app/shared/models/session';
import {UtilService} from '@app/shared/service/util.service';
import {CustoService} from '@app/shared/service/custo.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {SdkService} from '@app/shared/service/sdk.service';
import {ContentVersion} from '@app/shared/models/content_version';
import {MandatoryFileStatus, RemoteRelatedFile} from '@app/core/messaging/remote-related-file';
import * as _ from 'lodash';
import {RelatedContentVisibility} from '@app/core/messaging/display-related-content';
import {ConferenceEnableCommand} from '@app/core/messaging/conference-enable-command';
import {SessionPlayPauseState} from '@app/core/messaging/session-play-pause-state';
import {HighlightingModeCommand} from '@app/core/messaging/highlighting-mode-command';
import {SessionStatus} from '@app/core/messaging/session-state';

@Component({
    selector: 'app-action-bar',
    templateUrl: './action-bar.component.html',
    styleUrls: ['./action-bar.component.scss']
})
export class PresentationActionBarComponent implements OnInit, OnChanges, OnDestroy {

    @Input() session: Session;
    @Input() content: ContentVersion;
    @Input() actionBarsDisplayed: boolean;
    public chatEnabled = false;
    public chatDisplayed = false;
    public newChatMessages = 0;

    private _actionBarsDisplayedTimer: any = null;
    private actionSubscription: Subscription;
    public lockedScreen = false;
    private _relatedContentDisplay = false;
    private _noSleep: any;
    public hasPresenterMode = false;
    public hasInteractiveMode = false;
    public askingForHand = false;
    public canStream = false;
    public askingForMic = false;
    public isDesktop: boolean;
    public isOCE = false;

    public mandatoryFile: RemoteRelatedFile[] = [];
    public hiddenMandatoryFiles = true;
    private _subscriptions: Subscription[] = [];
    public isConferenceEnabled = false;
    public isSessionPaused: boolean;
    private _isExternalUser: boolean;

    public isHighlightingModeEnabled: boolean;
    public isToolbarDisplayed: boolean;
    public isColorpickerDisplayed: boolean;

    @ViewChild('mandatoryFileList') mandatoryFileList: ElementRef;

    @HostListener('window:resize', ['$event'])
    onResize() {
        this._initView();
    }

    /**
     * @function constructor
     * @param {CustoService} _custo
     * @param {AuthenticationService} authService
     * @param {PresentationEventsService} eventsService
     * @param {MessagingService} messagingService
     * @param {SdkService} _sdkService
     * @param {FlashService} flashService
     * @param {Router} router
     * @param {OpentokService} conferenceService
     * @param {TranslateService} translateService
     * @param {UtilService} _utilService
     * @param {BrowserService} _browserService
     */
    constructor(
        private _custo: CustoService,
        private authService: AuthenticationService,
        private eventsService: PresentationEventsService,
        private messagingService: MessagingService,
        private _sdkService: SdkService,
        private flashService: FlashService,
        private router: Router,
        private conferenceService: OpentokService,
        private translateService: TranslateService,
        private _utilService: UtilService,
        private _browserService: BrowserService
    ) {

        this.canStream = this.conferenceService.isPublishing();

        this.authService.isExternalUser().then((bool: boolean) => {
            this._isExternalUser = bool;
        });

        // subscribe to xmpp messages
        this._subscriptions.push(this.messagingService.Messages
            .subscribe((message: MessageInterface) => {
                    switch (message.constructor) {
                        case ChatMessage :
                            this.chatMessageReceived(<ChatMessage>message);
                            break;
                        case ConferenceEnableCommand:
                            const conferenceMessage = <ConferenceEnableCommand>message;
                            this.isConferenceEnabled = conferenceMessage.isConferenceEnabled;
                            break;
                        case SessionPlayPauseState:
                            const sessionPlayPauseState = <SessionPlayPauseState>message;
                            this.isSessionPaused = sessionPlayPauseState.state === 'paused';
                            if (!this.isSessionPaused) {
                                this.isHighlightingModeEnabled = false;
                                this.isColorpickerDisplayed = false;
                                if (this.hasPresenterMode) {
                                    this.giveControlBack();
                                }
                            }
                            break;
                        case HighlightingModeCommand:
                            const highlightingModeMessage = <HighlightingModeCommand>message;
                            this.isHighlightingModeEnabled = highlightingModeMessage.isEnabled;
                            break;
                        case SessionStatus:
                            const sessionStatusMessage = <SessionStatus>message;
                            this.isHighlightingModeEnabled = sessionStatusMessage.isHighlightingModeEnabled;
                            break;
                    }
                }
            ));
        this.eventsService.actionRequestsReplay
            .subscribe((action: PresentationResponse) => {
                switch (action.event) {
                    case PresentationEvent.remoteRelatedContentAction:
                        this._setMandatoryFiles(<RemoteRelatedFile[]>action.data);
                        break;
                    case PresentationEvent.isChatEnabled:
                        this.chatEnabled = action.data;
                        break;
                    case PresentationEvent.isConferenceEnabled:
                        this.isConferenceEnabled = action.data;
                        break;
                }
            });

        //subscribe to presentation messages
        this._subscriptions.push(this.eventsService.actionRequests
            .subscribe(
                (action: PresentationResponse) => {
                    switch (action.event) {
                        case PresentationEvent.HidePresentationActionBars:
                            this._hideActionBars();
                            break;
                        case PresentationEvent.ChatDisplayed:
                            this.chatDisplayed = true;
                            break;
                        case PresentationEvent.ChatClosed:
                            this.chatDisplayed = false;
                            break;
                        case PresentationEvent.StreamingDisabled:
                            this.canStream = false;
                            if (!this.lockedScreen) {
                                // enable nosleepjs when OpenTock stream is off
                                this.lockScreen();
                            }
                            break;
                        case PresentationEvent.StreamingAllowed:
                            this.canStream = true;
                            // disable nosleepjs when OpenTock stream (prevent performance issue)
                            this.unlockScreen();
                            break;
                        case PresentationEvent.relatedContentClick:
                            this._relatedContentDisplay = true;
                            break;
                        case PresentationEvent.askForHandNotification:
                            this.hasPresenterMode = action.data;
                            // Initializing variables when participant has just got the hand over
                            // Or when receiving a message from the presenter to get the hand over back
                            this.isHighlightingModeEnabled = false;
                            break;
                        case PresentationEvent.interactiveModeStatusNotification:
                            this.hasInteractiveMode = action.data;
                            // Deactivating highlighting mode if enabled after loosing interactive mode
                            if (!this.hasInteractiveMode && this.isHighlightingModeEnabled) {
                                this.isHighlightingModeEnabled = false;
                            }
                            break;
                    }
                }
            ));
    }

    ngOnInit() {
        if (this._browserService.isIE()) {
            this.mandatoryFileList.nativeElement.style.maxHeight = '200px';
        }

        this._noSleep = this._utilService.getNoSleepJs();
        this.isOCE = this.session.sessionType === SessionType.RemoteOCE;
        // Forcing OCE mode
        if (this._utilService.forcedStandalone()) {
            this.isOCE = true;
        }
        this.lockScreen();
        this._initView();
    }

    ngOnChanges(changes: any) {
        // Hiding toolbar panel when the bottom toolbar disappears
        if (changes.actionBarsDisplayed) {
            this.isToolbarDisplayed = false;
        }
    }

    ngOnDestroy() {
        this.unlockScreen();
        this._subscriptions.forEach((subscription: Subscription) =>
            subscription.unsubscribe()
        );
    }


    /**
     * @function isAudioVideoSession
     * @public
     * @return {boolean}
     */
    public isAudioVideoSession(): boolean {
        return this.session.audioType === SessionAudioType.Isharing;
    }

    /**
     * @function toWaitingRoom
     * @public
     * @return {void}
     */
    public toWaitingRoom(): void {
        this.unlockScreen();
        this.eventsService.goToWaitingRoom();
    }

    /**
     * @function logout
     * @public
     * @return {void}
     */
    public logout(): void {
        this.translateService.get('Are you sure you want to leave the presentation?')
            .subscribe((trans: string) => {
                if (confirm(trans)) {
                    this._utilService._doLogout();
                }
            });
    }

    /**
     * @function toggleConferencePanel
     * @public
     * @returns {void}
     */
    public toggleChatPanel(): void {
        this.newChatMessages = 0;
        if (this.chatDisplayed) {
            this.eventsService.showChatPanel(false);
        } else {
            this.eventsService.showChatPanel(true);
        }
    }

    /**
     * @function chatMessageReceived
     * @public
     * @param {ChatMessage} message
     * @returns {void}
     */
    public chatMessageReceived(message: ChatMessage): void {
        if (!this.chatDisplayed && message.username !== this.authService.credentials.username) {
            this.eventsService.notificationAction(message);
            this.newChatMessages++;
        }
    }

    /**
     * @function _enterFullScreen
     * @description
     * @public
     * @returns {void}
     */
    public toggleFullScreen(): void {
        if (!this.isFullScreen()) {
            this._enterFullScreen();
        } else {
            this._cancelFullScreen();
        }
    }

    /**
     * @function isFullScreen
     * @description
     * @private
     * @returns {boolean}
     */
    public isFullScreen(): boolean {
        const elem: any = document;
        return (elem.fullScreenElement && elem.fullScreenElement !== null) ||
            (elem.msFullscreenElement && elem.msFullscreenElement !== null) ||
            (elem.mozFullScreen || elem.webkitIsFullScreen);
    }

    /**
     * @function isIOS
     * @description
     * @public
     * @returns {boolean}
     */
    public isIOS(): boolean {
        return this._browserService.isIos();
    }

    /**
     * @function isExternalUser
     * @description
     * @public
     * @returns {boolean}
     */
    public isExternalUser(): boolean {
        return this._isExternalUser;
    }

    /**
     * @function _setMandatoryFiles
     * @description
     * @param {RemoteRelatedFile[]} relatedFiles
     * @private
     * @returns {void}
     */
    private _setMandatoryFiles(relatedFiles: RemoteRelatedFile[]): void {
        relatedFiles = _.filter(relatedFiles, (related: RemoteRelatedFile) => related.type === RelatedContentVisibility.MANDATORY);
        this.mandatoryFile = relatedFiles;
    }

    /**
     * @function toggleMandatoryFiles
     * @description
     * @public
     * @returns {void}
     */
    public toggleMandatoryFiles(): void {
        this.hiddenMandatoryFiles = !this.hiddenMandatoryFiles;
    }

    /**
     * @function openMandatoryFile
     * @description
     * @param {number} index
     * @public
     * @returns {void}
     */
    public openMandatoryFile(remoteRelate: RemoteRelatedFile): void {
        this.eventsService.remoteRelatedContentClickAction(remoteRelate);
        this.messagingService.mandatoryFileNotification(remoteRelate.name, MandatoryFileStatus.OPENED);
    }

    /**
     * @function requestStreamPublishing
     * @description
     * @public
     * @returns {void}
     */
    public requestStreamPublishing(): void {
        if (this.canStream || this.askingForMic) {
            return;
        }
        this.askingForMic = true;
        this.messagingService.requestAudioVideoStreaming();
        this.translateService.get('Micro request sent').subscribe(
            (text: string) => this.flashService.info(text)
        );
        setTimeout(() => {
            this.askingForMic = false;
        }, 5000);
    }

    /**
     * @function stopStreamPublishing
     * @description
     * @public
     * @returns {void}
     */
    public stopStreamPublishing(): void {
        this.conferenceService.disable();
    }

    /**
     * @function isMicrophoneActivated
     * @description
     * @public
     * @returns {boolean}
     */
    public isMicrophoneActivated(): boolean {
        return this.conferenceService.isPublishingAudio();
    }

    /**
     * @function isCameraActivated
     * @description
     * @public
     * @returns {boolean}
     */
    public isCameraActivated(): boolean {
        return this.conferenceService.isPublishingVideo();
    }

    /**
     * @function muteUnmute
     * @description
     * @public
     * @returns {void}
     */
    public muteUnmute(): void {
        this.eventsService.toggleMicro();
    }

    /**
     * @function toggleVideo
     * @description
     * @public
     * @returns {void}
     */
    public toggleVideo(): void {
        this.eventsService.toggleVideo();
    }

    /**
     * @function _enterFullScreen
     * @description
     * @private
     * @returns {void}
     */
    private _enterFullScreen(): void {
        const elem: any = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        } else if (elem.webkitRequestFullScreen) {
            elem.webkitRequestFullScreen();
        }
    }

    /**
     * @function _cancelFullScreen
     * @description
     * @private
     * @returns {void}
     */
    private _cancelFullScreen(): void {
        const elem: any = document;
        if (elem.exitFullScreen) {
            return elem.exitFullScreen();
        } else if (elem.webkitCancelFullScreen) {
            return elem.webkitCancelFullScreen();
        } else if (elem.msExitFullscreen) {
            return elem.msExitFullscreen();
        } else if (elem.mozCancelFullScreen) {
            return elem.mozCancelFullScreen();
        }
    }

    /**
     * @function _hiddingActionBar
     * @description
     * @private
     * @returns {void}
     */
    private _hideActionBars(): void {
        this.actionBarsDisplayed = false;
        // clearTimeout(this._actionBarsDisplayedTimer);
        this._actionBarsDisplayedTimer = null;
    }

    /**
     * @function lockScreen
     * @description
     * @public
     * @returns {void}
     */
    public lockScreen(): void {
        if (this.lockedScreen || this.canStream) {
            this.unlockScreen();
        } else {
            this.lockedScreen = true;
            this._noSleep.enable();
            console.log('screen locked : ' + !this._noSleep.noSleepVideo.paused);
        }
    }

    /**
     * @function unlockScreen
     * @description
     * @public
     * @returns {void}
     */
    public unlockScreen(): void {
        this.lockedScreen = false;
        this._noSleep.disable();
        console.log('screen locked : ' + !this._noSleep.noSleepVideo.paused);
    }

    /**
     * @function showDrawerAction
     * @description
     * @public
     * @returns {void}
     */
    public showDrawerAction(): void {
        this.eventsService.showDrawerAction();
    }

    /**
     * @function getControlRequest
     * @description
     * @public
     * @returns {void}
     */
    public getControlRequest(): void {
        this.askingForHand = true;
        this.messagingService.askForHand();
        this.translateService.get('Control request sent').subscribe(
            (text: string) => this.flashService.info(text)
        );
        setTimeout(() => {
            this.askingForHand = false;
        }, 10000);
    }

    /**
     * @function giveControlBack
     * @description
     * @public
     * @returns {void}
     */
    public giveControlBack(): void {
        this.hasPresenterMode = false;
        this.isHighlightingModeEnabled = false;
        this.isColorpickerDisplayed = false;
        this.messagingService.leaveHand();
        this.eventsService.isHandNotification(false);
    }

    /**
     * @function _initView
     * @description
     * @private
     * @returns {void}
     */
    private _initView() {
        if (this._isMobileView()) {
            this.isDesktop = false;
        } else if (this._isDesktopView()) {
            this.isDesktop = true;
        }
    }

    /**
     * @function _isMobileView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isMobileView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return this._browserService.isMobile() || (!this._browserService.isMobile() && windowWidth <= 823);
    }

    /**
     * @function _isDesktopView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isDesktopView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return !this._browserService.isMobile() && windowWidth > 823;
    }

    /**
     * @function toggleToolbarPanel
     * @description
     * @public
     * @returns {void}
     */
    public toggleToolbarPanel(): void {
        this.isToolbarDisplayed = !this.isToolbarDisplayed;
    }

    /**
     * @function togglePencilMode
     * @description
     * @public
     * @param {any} $events
     * @returns {void}
     */
    public togglePencilMode($event: any): void {
        this.isHighlightingModeEnabled = !this.isHighlightingModeEnabled;
        this.isColorpickerDisplayed = this.isHighlightingModeEnabled;
        this._sdkService.sdk.getPlayerJS().eventDispatcher({
            type: 'drawing-mode',
            isEnabled: this.isHighlightingModeEnabled,
            userID: this.authService.credentials.username
        });
    }

    /**
     * @function toggleColorpicker
     * @description
     * @public
     * @returns {void}
     */
    public toggleColorpicker($event: any): void {
        this.isColorpickerDisplayed = !this.isColorpickerDisplayed;
        this._sdkService.sdk.getPlayerJS().eventDispatcher({ type: 'colorpiker-action', show: this.isColorpickerDisplayed });
    }

    /**
     * @function getPencilClassnames
     * @description
     * @public
     * @returns {string}
     */
    public getPencilClassnames(): string {
        return this.isHighlightingModeEnabled ? 'active' : '';
    }

    /**
     * @function getColorpickerClassnames
     * @description
     * @public
     * @returns {string}
     */
    public getColorpickerClassnames(): string {
        let classnames = '';
        if (!this.isHighlightingModeEnabled) {
            classnames += 'disabled';
        } else if (this.isHighlightingModeEnabled && this.isColorpickerDisplayed) {
            classnames += 'active';
        }
        return classnames;
    }

}
