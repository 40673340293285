export const environment = {
    env: 'dev-remote',
    proxyConfig: false,
    production: true,
    version: '',
    serverUrl: 'https://iadmin.dev.kadrige.com/kis',
    serverApiUrl: 'https://iadmin.dev.kadrige.com/api',
    serverApiPrefUrl: 'https://iadmin.dev.kadrige.com/preferences',
    serverApiOTUrl: 'https://iadmin.dev.kadrige.com/tokbox',
    resetPwdUrl: 'https://iadmin.dev.kadrige.com/resetting/request',
    changePwdUrl: 'https://iadmin.dev.kadrige.com/common-manager/#/change-password',
    authUrl: 'https://iadmin.dev.kadrige.com/api/oauth',
    unzipContenUrl: 'https://guest.isharing.dev.kadrige.com/contents/unzip-content.php',
    contentUrl: 'https://guest.isharing.dev.kadrige.com/contents',
    authClientId: '4jej02k21p4w8w48kww08gsg8gwckwsgkcg48oocgwkkwoso4k',
    sdkJsPlayer: 'assets/js/sdk.min.js',
    webSocketUrl: 'wss://iadmin.dev.kadrige.com:443/wss/',
    defaultLanguage: 'en-US',
    supportedLanguages: ['bg-BG', 'cs-CZ', 'de-DE', 'el-GR', 'en-US', 'es-ES', 'fr-FR', 'he-IL', 'hu-HU', 'it-IT', 'ja-JP', 'ko-KR', 'nl-NL', 'pl-PL', 'pt-BR', 'pt-PT', 'ro-RO', 'ru-RU', 'sk-SK', 'th-TH', 'tr-TR', 'uk-UA', 'vi-VN', 'zh-Hans'],
    opentokApiKey : '46167202'
};