  import {AfterViewInit, Component, Directive, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
  import {
    PresentationEvent,
    PresentationEventsService,
    PresentationResponse
  } from '@app/home/session/presentation/presentation-events.service';
  import {Subscription} from 'rxjs';
  import {ContentVersion} from '@app/shared/models/content_version';
  import {AuthenticationService} from '@app/core/authentication/authentication.service';
  import {MessagingService} from '@app/core/messaging/messaging.service';
  import {RelatedContent} from '@app/shared/models/related_content';
  import {TabsetComponent} from 'ngx-bootstrap';
  import * as _ from 'lodash';
  import {AttendeesService} from '@app/shared/service/attendees.service';
  import {RelatedContentVisibility} from '@app/core/messaging/display-related-content';
  import {Session, SessionType} from '@app/shared/models/session';
  import {SessionService} from '@app/shared/service/session.service';
  import {MandatoryFileStatus, RemoteRelatedFile} from '@app/core/messaging/remote-related-file';
  import {UtilService} from '@app/shared/service/util.service';
  import {CurrentSlide} from '@app/core/messaging/current-slide';
  import {DynamicContentService} from '@app/shared/service/dynamicContent.service';
  import {LoadSequence, LoadSlide} from '@app/core/messaging/load-slide';
  import {DragDropModule} from '@angular/cdk/drag-drop';
  import {MessageInterface} from '@app/core/messaging/message';
  import {environment} from '@env/environment';
  import {SessionStatus} from '@app/core/messaging/session-state';
  import {fromEvent} from 'rxjs/index';

  declare var webkitSpeechRecognition: any;
  declare var SpeechRecognition: any;

  @Component({
    selector: 'app-drawer',
    templateUrl: './drawer.component.html',
    styleUrls: ['./drawer.component.scss']
  })
  export class DrawerComponent implements OnInit, AfterViewInit, OnDestroy {

    private _actionSubscription: Subscription[] = [];
    public collapse: boolean = true;
    public customClass: string = 'content-header';
    public tabClass: string = 'tabClass';
    public access_token: string;
    public search: string;
    public contentsArray: any = []; //ContentVersion[] = [];
    public searchRelated: string;
    public currentDrawerAccordionsOpeningState: any = {};
    public currentRelatedAccordionsOpeningState: any = {};
    public lastDrawerAccordionsOpeningState: any = {};
    public lastDrawerAccordionsOpeningStateBeforeSearch: any = {};
    public lastRelatedAccordionsOpeningState: any = {};
    public hasPresenterMode: boolean = false;
    public hasInteractiveMode: boolean = false;
    public activeSlide: any = {};
    public remoteRelatedArray: RemoteRelatedFile[] = [];
    public searchSequence: string;

    private _connectedJid: string = null;
    private _isSpeaker: boolean = false;
    private _isExpert: boolean = false;

    public oneAtATime: boolean = false; // Does not work with isOpen directive

    public isOCE: boolean = false;


    public remoteSlideContainer: boolean = false;
    public presentationIdentifier: string;
    public sequenceIdentifier: string;
    public slideIndex: number = 0;
    public currentRemoteSlides: any[] = [];
    public remoteContentsArray: any[];

    public showPrevNextSequenceComponent: boolean;
    public activeAndAroundSequences: any = {};
    public canGoToPrevSequence: boolean = false;
    public canGoToNextSequence: boolean = false;
    private _wheelEvent: any;
    private _hasDetectDirection: boolean = false;
    private _keyComboEvent: any;

    @Input() session: Session;
    @Input() contents: ContentVersion[];
    @Input() actionBarsDisplayed: boolean;
    @ViewChild('drawerTabs') drawerTabs: TabsetComponent;

    /**
     * @function constructor
     * @param {PresentationEventsService} _eventsService
     * @param {AuthenticationService} _authService
     * @param {MessagingService} _messagingService
     */
    constructor(
      private _eventsService: PresentationEventsService,
      private _authService: AuthenticationService,
      private _messagingService: MessagingService,
      private _attendees: AttendeesService,
      private _sessionService: SessionService,
      private _attendeesService: AttendeesService,
      private _utilService: UtilService,
      private _dynamicContentService: DynamicContentService
    ) {}

    /**
     * @function ngOnInit
     */
    ngOnInit() {
      this.access_token = this._authService.credentials.access_token;
      this._connectedJid = this._authService.credentials.username;
      this._isSpeaker = this._sessionService.isSpeaker(this._connectedJid);
      //this._isExpert = this._attendeesService.isExpert(this._connectedJid);

      this.isOCE = this.session.sessionType === SessionType.RemoteOCE;
      // Forcing OCE mode
      if (this._utilService.forcedStandalone()) {
        this.isOCE = true;
      }

      if (this.isOCE) {
        // get expert contents
        const expertArray = _.filter(this.contents, (p: ContentVersion) => this._attendees.isExpert(p.content_author.uid));
        // filter expert content by name
        this.contentsArray = _.orderBy(expertArray, ['name'], ['asc']);
        // get other contents (speaker)
        let speakerArray = _.reject(this.contents, (p: ContentVersion) => this._attendees.isExpert(p.content_author.uid));
        // filter speaker content by name
        // speakerArray = _.orderBy(speakerArray, ['name'], ['asc']);

        this.contentsArray.push(...speakerArray);

        // init the array of the current active slide
        /*this.contentsArray.forEach((elt: ContentVersion, index: number) => {
          const slideArray = [];
          for (let i = 0; i < elt.slides.length; i++) {
            slideArray[i] = false;
          }
          //this.activeSlide[index] = slideArray;
        });*/
      }

      this._setParticipantAccess();

      this._actionSubscription.push(
          this._eventsService.actionRequests
            .subscribe((action: PresentationResponse) => {
                switch (action.event) {
                    case PresentationEvent.collapseDrawer:
                        this.toggleDrawer();
                        break;
                    case PresentationEvent.askForHandNotification:
                        this.hasPresenterMode = action.data;
                        if (this.hasPresenterMode) {
                            this._setPresenterAccess();
                            this.saveActiveAndAroundSequences();
                            this._enableFingerSwipingSequences();
                            this._enableSpeechRegonition(); // Experimental sequence control
                        } else {
                            this._setParticipantAccess();
                            this._disableFingerSwipingSequences();
                            this._disableSpeechRegonition(); // Experimental sequence control
                        }
                        break;
                    case PresentationEvent.interactiveModeStatusNotification:
                        this.hasInteractiveMode = action.data;
                        // this._setParticipantAccess();
                        break;
                    case PresentationEvent.updateDrawer:
                        if (action.data) {
                            this.updateDrawer(action.data);
                            setTimeout(() => {
                                this.saveActiveAndAroundSequences();
                            }, 500);
                            this._hasDetectDirection = false;
                        }
                        break;
                }
            })
      );

      this._actionSubscription.push(
          this._eventsService.actionRequestsReplay
            .subscribe((action: PresentationResponse) => {
                switch (action.event) {
                    case PresentationEvent.remoteRelatedContentAction:
                        this._setMandatoryFiles(<RemoteRelatedFile[]>action.data);
                        break;
                    case PresentationEvent.sessionDrawerInfo:
                        if (action.data) {
                            this.remoteContentsArray = action.data;
                            this._buildOCEDrawer();
                        }
                        break;
                }
            })
      );
    }

    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        this._actionSubscription.forEach((subscription: Subscription) => subscription.unsubscribe());
    }



    private _buildOCEDrawer(): void {
      const remoteArray: any = {};
      // Looping on presentations
      this.remoteContentsArray.forEach((presentation: any, index: number) => {
        const presentationId: string = presentation.identifier;
        remoteArray[presentationId] = {};

        // Looping on sequences
        for (let i = 0; i < presentation.sequences.length; i++) {
          const sequence: any = presentation.sequences[i];
          const sequenceId: string = sequence.identifier;
          const slides = sequence.slides;
          remoteArray[presentationId][sequenceId] = { 'sequence': false, 'slides': [] };

          // Looping on slides
          if (slides.length > 0) {
            for (let j = 0; j < slides.length; j++) {
              remoteArray[presentationId][sequenceId]['slides'][j] = false;
              slides[j]['presentationIdentifier'] = presentationId;
              slides[j]['sequenceIdentifier'] = sequenceId;
              slides[j]['slideIndex'] = j;
            }
          }
        }
      });
      this.activeSlide = remoteArray;

      this._initAccordionsOpeningState();
    }

    /**
     * @function ngAfterViewInit
     */
    ngAfterViewInit() {
    }

    /**
    * @function _enableFingerSwipingSequences
    * @description
    * @private
    * @return {void}
    */
    private _enableFingerSwipingSequences(): void {
      this._wheelEvent = fromEvent(document.body, 'wheel', { passive: false }).subscribe((event: WheelEvent) => {
          let posX = 0;
          posX = posX - event.deltaX * 2;

          // Swiping left
          if (posX < -100 && !this._hasDetectDirection) {
              this.goToPreviousSequence();
              this._hasDetectDirection = true;
          }
          // Swiping right
          else if (posX > 100 && !this._hasDetectDirection) {
              this.goToNextSequence();
              this._hasDetectDirection = true;
          }
      });
    }

    /**
    * @function _disableFingerSwipingSequences
    * @description
    * @private
    * @return {void}
    */
    private _disableFingerSwipingSequences(): void {
        this._wheelEvent.unsubscribe();
    }

    /**
     * @function _enableSpeechRegonition
     * @description
     * @private
     * @return {void}
     */
    private _enableSpeechRegonition() {
        const SpeechRecog: any = webkitSpeechRecognition || SpeechRecognition;
        if (!SpeechRecog) {
             return;
        }

        let recognizing: boolean;
        const directions = ['previous' , 'next'];
        const recognition = new SpeechRecog();
        recognition.lang = 'en-US';
        recognition.interimResults = false;
        recognition.maxAlternatives = 1;

        document.body.focus();
        this._keyComboEvent = fromEvent(window, 'keydown').subscribe((e: any) => {
            if (e.code === 'Space' && !recognizing) {
                recognition.start();
                console.log('Ready to receive a direction command.');
            }
        });

        recognition.onstart = function (event: any) {
            recognizing = true;
        };
        recognition.onend = function (event: any) {
            recognizing = false;
        };
        recognition.onerror = function (event: any) {
            recognizing = false;
        };
        recognition.onspeechend = (event: any) => {
            recognition.stop();
        };
        recognition.onnomatch = (event: any) => {

        };
        recognition.onresult = (event: any) => {
            const last = event.results.length - 1;
            const direction = event.results[last][0].transcript;
            if (direction === directions[0]) {
                this.goToPreviousSequence();
            } else if (direction === directions[1]) {
                this.goToNextSequence();
            }
        };
    }

    /**
     * @function _disableSpeechRegonition
     * @description
     * @private
     * @return {void}
     */
    private _disableSpeechRegonition() {
        this._keyComboEvent.unsubscribe();
    }

    /**
     * @function _focusTab
     * @description
     * @private
     * @param {number} index
     * @param {boolean} bool
     * @return {void}
     */
    private _focusTab(index: number, bool: boolean): void {
      if (this.drawerTabs.tabs.length > 0 && this.drawerTabs.tabs[index]) {
        this.drawerTabs.tabs[index].active = bool;
      }
    }

    /**
     * @function _setMandatoryFiles
     * @description
     * @param {RemoteRelatedFile[]} relatedFiles
     * @private
     * @returns {void}
     */
    private _setMandatoryFiles(relatedFiles: RemoteRelatedFile[]): void {
      relatedFiles = _.filter(relatedFiles, (related: RemoteRelatedFile) => related.type === RelatedContentVisibility.PUBLIC_REMOTE);
      this.remoteRelatedArray = relatedFiles;
    }

    /**
     * @function remoteRelatedContentClick
     * @description
     * @public
     * @param {RemoteRelatedFile} related
     * @returns {void}
     */
    public remoteRelatedContentClick(related: RemoteRelatedFile): void {
      this._eventsService.remoteRelatedContentClickAction(related);
      this._messagingService.mandatoryFileNotification(related.name, MandatoryFileStatus.OPENED);
    }

    /**
     * @function _disableTab
     * @description
     * @private
     * @param {number} index
     * @param {boolean} bool
     * @return {void}
     */
    private _disableTab(index: number, bool: boolean): void {
      if (this.drawerTabs.tabs.length > 0) {
        this.drawerTabs.tabs[index].disabled = bool;
      }
    }

    /**
     * @function _setParticipantAccess
     * @description
     * @private
     * @return {void}
     */
    private _setParticipantAccess(): void {
      // Focusing related contents tab
      this._focusTab(1, true);
      // Deactivating main content tab
      this._disableTab(0, true);
    }

    /**
     * @function _setPresenterAccess
     * @description
     * @private
     * @return {void}
     */
    private _setPresenterAccess(): void {
      // Focusing related contents tab
      this._focusTab(0, true);
      // Deactivating main content tab
      this._disableTab(0, false);
    }

    /**
     * @function _initAccordionsOpeningState
     * @description
     * @private
     * @return {void}
     */
    private _initAccordionsOpeningState() {
      const presentations: any = this.isOCE ? this.remoteContentsArray : this.contents;
      let firstContentIdWithRelatedDocs: number = null;
      for (let i = 0; i < presentations.length; i++) {
        // Main content opening state
        if (i === 0) {
          this.currentDrawerAccordionsOpeningState[i] = true;
        } else {
          this.currentDrawerAccordionsOpeningState[i] = false;
        }
        // Related opening state
        if (presentations[i].relateds && presentations[i].relateds.length) {
          if (firstContentIdWithRelatedDocs === null) {
            firstContentIdWithRelatedDocs = i;
            this.currentRelatedAccordionsOpeningState[i] = true;
          } else {
            this.currentRelatedAccordionsOpeningState[i] = false;
          }
        }
      }

      this.lastDrawerAccordionsOpeningState = JSON.parse(JSON.stringify(this.currentDrawerAccordionsOpeningState));
      this.lastDrawerAccordionsOpeningStateBeforeSearch = JSON.parse(JSON.stringify(this.currentDrawerAccordionsOpeningState));
    }

    /**
     * @function toggleDrawer
     * @public
     * @return {void}
     */
    public toggleDrawer(): void {
      // back to slide tab
      if (this.drawerTabs.tabs.length > 0) {
        this.drawerTabs.tabs[0].active = true;
      }

      this.collapse = !this.collapse;
    }

    /**
     * @function loadSlide
     * @public
     * @param slide
     * @param {any} $event
     * @param {number} index
     * @param {string} presentationIdentifier
     * @param {string} sequenceIdentifier
     * @return {void}
     */
    public loadSlide($event:any, slide: any, index: number, presentationIdentifier: string, sequenceIdentifier: string): void {
      // Avoiding click on presentation header
      $event.stopImmediatePropagation();

      if (!this.isOCE) {
        // init array of current active slide
        /*this.activeSlide.forEach((elt) => {
          elt.fill(false);
        });*/
        // new current active slide
        this.activeSlide[index][sequenceIdentifier] = true;
        const size = slide.size;
        const slideURI = slide.archive_resource_uri;
        const contentVersion = this.contentsArray[index].version;
        const contentVersionID = this.contentsArray[index].version_id;

        this._messagingService.changeSlide(slideURI, contentVersion, contentVersionID, size);
        this.collapse = true;
      } else {
        this.presentationIdentifier = presentationIdentifier;
        this.sequenceIdentifier = sequenceIdentifier;
        this.collapse = true;

        // Saving accordion states
        this.lastDrawerAccordionsOpeningState = JSON.parse(JSON.stringify(this.currentDrawerAccordionsOpeningState));

        const nbSlides = this.activeSlide[presentationIdentifier][sequenceIdentifier].slides.length;
        // multi-slides sequence => show slides container
        if (nbSlides > 1) {
          const presentation = this.remoteContentsArray.find((presentation: any) => presentation.identifier === presentationIdentifier);
          const sequence = presentation.sequences.find((sequence: any) => sequence.identifier === sequenceIdentifier);
          this.currentRemoteSlides = sequence.slides;
          this.slideIndex = 0;
          this.remoteSlideContainer = true;
          this.collapse = false;
        }
        // Only one slide in the sequence, do not display slides panel and loading directly the only slide
        else if (nbSlides === 1) {
            this._sendLoadSlideAction(slide.slides[0]);
        }
      }
    }

    /**
     * @function loadRemoteSlide
     * @public
     * @param {slide} CurrentSlide
     * @param {number} slideIndex
     * @return {void}
     */
    public loadRemoteSlide(slide: any, slideIndex: number): void {
        this._initRemoteCurrentSlide();
        this.activeSlide[slide.presentationIdentifier][slide.sequenceIdentifier].sequence = true;
        this.activeSlide[slide.presentationIdentifier][slide.sequenceIdentifier].slides[slideIndex] = true;
        this.collapse = true;
        this._sendLoadSlideAction(slide);
    }

    /**
     * @function _sendLoadSlideAction
     * @description
     * @param {any} slide
     * @returns {slide}
     */
    private _sendLoadSlideAction(slide: any) {
        const currentSlide = new CurrentSlide();
        currentSlide.slideURL = slide.networkURL;
        currentSlide.slideName = slide.name;
        currentSlide.size = slide.size;
        currentSlide.presentationIdentifier = slide.presentationIdentifier;
        currentSlide.sequenceIdentifier = slide.sequenceIdentifier;
        currentSlide.timestamp = new Date().getTime();
        currentSlide.animation = 'none';
        currentSlide.dynamicContent = JSON.stringify(this._dynamicContentService.dynamicContent);
        this._messagingService.presentationSlideLoadCommand(currentSlide);
    }

    /**
     * @function relatedContentClick
     * @public
     * @param {RelatedContent} related
     * @return {void}
     */
    public relatedContentClick(related: RelatedContent): void {
      this._eventsService.relatedContentClickAction(related);
      this.collapse = true;
    }

    /**
     * @function accordionStateChanged
     * @description
     * @public
     * @param {string} type
     * @param {number} index
     * @return {void}
     */
    public accordionStateChanged(type: string, index: number): void {
      if (type === 'main') {
        // Updating current accordion state
        this.currentDrawerAccordionsOpeningState[index] = !this.currentDrawerAccordionsOpeningState[index];
        // Resetting all others
        for (const i in this.currentDrawerAccordionsOpeningState) {
          if (parseInt(i) !== index) {
              this.currentDrawerAccordionsOpeningState[i] = false;
          }
        }
        // Saving last accordions states
        this.lastDrawerAccordionsOpeningState = JSON.parse(JSON.stringify(this.currentDrawerAccordionsOpeningState));
        this.lastDrawerAccordionsOpeningStateBeforeSearch = JSON.parse(JSON.stringify(this.currentDrawerAccordionsOpeningState));
      } else if (type === 'related') {
        this.currentRelatedAccordionsOpeningState[index] = !this.currentRelatedAccordionsOpeningState[index];
        // Saving last accordions states
        this.lastRelatedAccordionsOpeningState = JSON.parse(JSON.stringify(this.currentRelatedAccordionsOpeningState));
      }
    }

    /**
     * @function openAllAccordions
     * @description
     * @public
     * @param {string} type
     * @return {void}
     */
    public openAllAccordions(type: string): void {
        // Resetting all accordions states
        if (this.search === '') {
            this.resetAllAccordionsPreviousState(type);
        }
        // Opening all accordions
        else {
            const presentations: any = this.isOCE ? this.remoteContentsArray : this.contents;
            for (let i = 0; i < presentations.length; i++) {
                if (type === 'main') {
                    this.currentDrawerAccordionsOpeningState[i] = true;
                } else if (type === 'related') {
                    this.currentRelatedAccordionsOpeningState[i] = true;
                }
            }
        }
    }

    /**
     * @function openAccordionbyPresentation
     * @description
     * @public
     * @param {string} type
     * @param {string} presentationIdentifier
     * @return {void}
     */
    public openAccordionbyPresentation(presentationIdentifier: string, type: string): void {
      const presentations: any = this.isOCE ? this.remoteContentsArray : this.contents;
      if (type === 'main') {
        const presentations: any = this.isOCE ? this.remoteContentsArray : this.contents;
        for (let i = 0; i < presentations.length; i++) {
            if (type === 'main') {
                if (presentations[i].identifier === presentationIdentifier) {
                    this.currentDrawerAccordionsOpeningState[i] = true;
                } else {
                    this.currentDrawerAccordionsOpeningState[i] = false;
                }
            }
        }
      }

      this.lastDrawerAccordionsOpeningState = JSON.parse(JSON.stringify(this.currentDrawerAccordionsOpeningState));
    }

    /**
     * @function openSlidePanelBySequence
     * @description
     * @public
     * @param {any} slides
     * @return {void}
     */
    public openSlidePanelBySequence(slide: any):void {
      this.currentRemoteSlides = slide;
      this.remoteSlideContainer = true;
    }

    /**
     * @function resetAllAccordionsPreviousState
     * @description
     * @public
     * @param {string} type
     * @return {void}
     */
    public resetAllAccordionsPreviousState(type: string): void {
      if (type === 'main') {
        if (this.search === '') {
            this.currentDrawerAccordionsOpeningState = JSON.parse(JSON.stringify(this.lastDrawerAccordionsOpeningStateBeforeSearch));
        } else {
            this.currentDrawerAccordionsOpeningState = JSON.parse(JSON.stringify(this.lastDrawerAccordionsOpeningState));
        }
      } else if (type === 'related') {
        this.currentRelatedAccordionsOpeningState = JSON.parse(JSON.stringify(this.lastRelatedAccordionsOpeningState));
      }
    }

    /**
     * @function saveAccordionStatesBeforeSearch
     * @description Save the accordion states before a search is done.
     *              When a search is done the accordion is fully opened.
     *              We need to remember it in case the user opens a slide panel and then goes back to the presentations/sequences,
     *              and clean the search bar to get the original accordion before the search has been done.
     * @public
     * @returns {void}
     */
    public saveAccordionStatesBeforeSearch(): void {
      if (this.search == undefined || this.search === '') {
          this.lastDrawerAccordionsOpeningStateBeforeSearch = JSON.parse(JSON.stringify(this.currentDrawerAccordionsOpeningState));
      }
    }

    /**
     * @function shouldBeDisplayed
     * @description
     * @param {ContentVersion} related
     * @returns {boolean}
     */
    public shouldBeDisplayed(related: RelatedContent): boolean {
      // Is the related doc attached to an expert contents
      /*const fromExpert:boolean = false;
      this.session.members.find((member) => {
          return member.role === 'expert';
      }).memberDN;*/

      // this._isExpert

      // @TODO: /!\ DEAL WITH EXPERT BEHAVIOR /!\

      // Case Presenter with control
      if (this._isSpeaker && this.hasPresenterMode) {
        if (related.type === RelatedContentVisibility.PUBLIC || related.type === RelatedContentVisibility.PRIVATE || related.type === RelatedContentVisibility.SHARED) {
          return true;
        }
        return false;
      }
      // Case Presenter without control
      else if (this._isSpeaker && !this.hasPresenterMode) {
        if (related.type === RelatedContentVisibility.PUBLIC || related.type === RelatedContentVisibility.PRIVATE) {
          return true;
        }
        return false;
      }
      // Case Participant without control
      else if (!this._isSpeaker && !this.hasPresenterMode) {
        if (related.type === RelatedContentVisibility.PUBLIC) {
          return true;
        }
        return false;
      }
      // Case Participant with control
      else if (!this._isSpeaker && this.hasPresenterMode) {
        if (related.type === RelatedContentVisibility.PUBLIC) { // || related.type === RelatedContentVisibility.SHARED
          // @TODO: ADD SHARED when participant will be able to send shared related docs to others
          return true;
        }
        return false;
      }
    }

    /**
     * @function hideRemoteSlidesContainer
     * @description
     * @returns {void}
     */
    public hideRemoteSlidesContainer(): void {
      this.remoteSlideContainer = false;
      this.resetAllAccordionsPreviousState('main');
    }

    /**
     * @function _initRemoteCurrentSlide
     * @private
     * @return {void}
     */
    private _initRemoteCurrentSlide(): void {
      // init array of current active slide
      for (const i in this.activeSlide) {
        for (const j in this.activeSlide[i]) {
          const sequence = this.activeSlide[i][j];
          sequence.sequence = false;
          sequence.slides.fill(false);
        }
      }
    }

    /**
     * @function saveActiveAndAroundSequences
     * @description
     * @public
     * @returns {void}
     */
    public saveActiveAndAroundSequences(): void {
        const presentation = this.remoteContentsArray && this.remoteContentsArray.find((presentation: any) => presentation.identifier === this.presentationIdentifier);
        const sequences = presentation && presentation.sequences;
        let activeSequenceIndex: number = null;
        let activeSequence: any;
        let previousSequenceIndex: number = null;
        let previousSequence: any = null;
        let nextSequenceIndex: number = null;
        let nextSequence: any = null;

        if (presentation && sequences && sequences.length > 1) {
          for (let key in sequences) {
              const obj = sequences[key];
              if (obj.identifier === this.sequenceIdentifier) {
                  activeSequenceIndex = parseInt(key);
                  activeSequence = obj;
              }
          }
          this.showPrevNextSequenceComponent = true;
        } else {
          this.showPrevNextSequenceComponent = false;
        }

        if (presentation && sequences && activeSequenceIndex - 1 >= 0) {
            previousSequenceIndex = activeSequenceIndex - 1;
            previousSequence = sequences[previousSequenceIndex];
            this.canGoToPrevSequence = true;
        } else {
            this.canGoToPrevSequence = false;
        }

        if (presentation && sequences && activeSequenceIndex + 1 < sequences.length) {
            nextSequenceIndex = activeSequenceIndex + 1;
            nextSequence = sequences[nextSequenceIndex];
            this.canGoToNextSequence = true;
        } else {
            this.canGoToNextSequence = false;
        }

        this.activeAndAroundSequences = {
            previousSequenceIndex: previousSequenceIndex,
            previousSequence: previousSequence,
            activeSequenceIndex: activeSequenceIndex,
            activeSequence: activeSequence,
            nextSequenceIndex: nextSequenceIndex,
            nextSequence: nextSequence
        };
    }

    /**
     * @function goToPreviousSequence
     * @description
     * @public
     * @returns {void}
     */
    public goToPreviousSequence(): void {
      if (!this.canGoToPrevSequence) {
        return;
      }

      const previousSequence = this.activeAndAroundSequences.previousSequence;
      const previousSequenceIndex = this.activeAndAroundSequences.previousSequenceIndex;
      if (previousSequence) {
        this.loadRemoteSlide(previousSequence.slides[0], 0);
          this.canGoToPrevSequence = true;
      } else {
        this.canGoToPrevSequence = false;
      }
    }

    /**
     * @function goToNextSequence
     * @description
     * @public
     * @returns {void}
     */
    public goToNextSequence(): void {
      if (!this.canGoToNextSequence) {
          return;
      }
      const nextSequence = this.activeAndAroundSequences.nextSequence;
      const nextSequenceIndex = this.activeAndAroundSequences.nextSequenceIndex;
      if (nextSequence) {
          this.loadRemoteSlide(nextSequence.slides[0], 0);
          this.canGoToNextSequence = true;
      } else {
          this.canGoToNextSequence = false;
      }
    }

    /**
     * @function getActiveSequenceTitle
     * @description
     * @public
     * @returns {string}
     */
    public getActiveSequenceTitle(): string {
        const presentation = this.remoteContentsArray && this.remoteContentsArray.find((presentation: any) => presentation.identifier === this.presentationIdentifier);
        const sequence = presentation && presentation.sequences.find((sequence: any) => sequence.identifier === this.sequenceIdentifier);
        return sequence && sequence.title || '';
    }

    /**
     * @function isSequenceActive
     * @description
     * @public
     * @param {string} presentationIdentifier
     * @param {string} sequenceIdentifier
     * @returns {boolean}
     */
    public isSequenceActive(presentationIdentifier: string, sequenceIdentifier: string): boolean {
      return this.activeSlide &&
          this.activeSlide[presentationIdentifier] &&
          this.activeSlide[presentationIdentifier][sequenceIdentifier] &&
          this.activeSlide[presentationIdentifier][sequenceIdentifier].sequence;
    }

    /**
     * @function isSlideActive
     * @description
     * @public
     * @param {number} slideIndex
     * @returns {boolean}
     */
    public isSlideActive(slideIndex: number): boolean {
      return this.activeSlide &&
          this.activeSlide[this.presentationIdentifier] &&
          this.activeSlide[this.presentationIdentifier][this.sequenceIdentifier] &&
          this.activeSlide[this.presentationIdentifier][this.sequenceIdentifier].slides &&
          this.activeSlide[this.presentationIdentifier][this.sequenceIdentifier].slides[slideIndex];
    }

    /**
     * @function getSlideIndex
     * @description
     * @private
     * @param {string} presentationIdentifier
     * @param {string} sequenceIdentifier
     * @param {string} slideName
     * @returns {number}
     */
    private getSlideIndex(presentationIdentifier: string, sequenceIdentifier: string, slideName: string): number {
        const presentation = this.remoteContentsArray && this.remoteContentsArray.find((presentation: any) => presentation.identifier === presentationIdentifier);
        const sequence = presentation && presentation.sequences.find((sequence: any) => sequence.identifier === sequenceIdentifier);
        const slideIndex = sequence && sequence.slides.findIndex((slide: any) => slide.name === slideName);
        return slideIndex;
    }

    /**
     * @function updateDrawer
     * @description
     * @public
     * @param {LoadSlide} data
     * @returns {void}
     */
    public updateDrawer(data: LoadSlide): void {
      setTimeout(() => {
        this.hideRemoteSlidesContainer();
        this._initRemoteCurrentSlide();
        const presentationIdentifier = data.presentationIdentifier;
        const sequenceIdentifier = data.sequenceIdentifier;
        const slideName = data.slideName;
        const slideIndex = this.getSlideIndex(presentationIdentifier, sequenceIdentifier, slideName);

        const presentation = this.remoteContentsArray && this.remoteContentsArray.find((presentation: any) => presentation.identifier === presentationIdentifier);
        const sequence = presentation && presentation.sequences.find((sequence: any) => sequence.identifier === sequenceIdentifier);

        this.presentationIdentifier = presentationIdentifier;
        this.sequenceIdentifier = sequenceIdentifier;
        this.slideIndex = slideIndex;

        this.setItemsAsActive(presentationIdentifier, sequenceIdentifier, slideIndex);
        this.openAccordionbyPresentation(presentationIdentifier, 'main');

        if (sequence.slides && sequence.slides.length > 1) {
            this.openSlidePanelBySequence(sequence.slides);
        }
      }, 0);
    }

    /**
     * @function setItemsAsActive
     * @description
     * @private
     * @param {string} presentationIdentifier
     * @param {string} sequenceIdentifier
     * @param {number} slideIndex
     * @returns {void}
     */
    private setItemsAsActive(presentationIdentifier: string, sequenceIdentifier: string, slideIndex: number): void {
      this.activeSlide[presentationIdentifier][sequenceIdentifier].sequence = true;
      this.activeSlide[presentationIdentifier][sequenceIdentifier].slides.fill(false);
      this.activeSlide[presentationIdentifier][sequenceIdentifier].slides[slideIndex] = true;
    }

  }
