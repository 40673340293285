import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { finalize } from 'rxjs/operators';

import { Gtu } from '@app/shared/models/gtu';

import { ComponentWrapperComponent } from '@app/shared/components/componentWrapper.component';
import { GrpdComponent } from './grpd/grpd.component';
import { RecoverPwdComponent } from './recover-pwd/recover-pwd.component';

import { I18nService } from '@app/core/i18n.service';
import { Logger } from '@app/core/logger.service';
import { CustoService } from '@app/shared/service/custo.service';
import { AuthenticationService, ErrorMessage } from '@app/core/authentication/authentication.service';
import { GtuService } from '@app/shared/service/gtu.service';
import { FlashService } from '@app/shared/flash/flash.service';
import { VersionService } from '@app/shared/service/version.service';
import { BrowserService } from '@app/shared/service/browser.service';

import { environment } from '@env/environment';

const log = new Logger('Login');

export enum TokenAction {
  JOIN = 'join'
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

    /**
     * Data members
     */
    public paramsLoaded: boolean;
    public custoLoaded: boolean;
    public version: string;
    public loginForm: FormGroup;
    public isLoading = false;
    public modalType: string;
    public modalUrlToLoad: string;
    public modalTransToLoad: string;
    public modalComponentToLoad: any;
    public isModalDisplayed: boolean;
    public isLegalDisplayed: boolean;
    public isDisclaimerDisplayed: boolean;
    public isGrpdDisplayed: boolean;
    public isExternalAccess: boolean;
    public isDesktop: boolean;
    public showLanguagesDropdown: boolean;
    public PARAMS: any = {};
    public CUSTO: any = {};
    private _translations: string[] = [];
    private _token: string;
    private _action: string;
    private _uid: string;
    private _forceDisclaimers: string;

    @HostListener('window:resize', ['$event'])
    onResize() {
        this._initView();
    }

    @HostListener('window:click', ['$event'])
    onClick($event: any) {
        const target = $event.target;
        const parentTarget = target.parentElement;
        if (target.id !== 'language-dropdown' && !target.classList.contains('dropdown-menu') &&
            parentTarget.id !== 'dropdown-menu' && !parentTarget.classList.contains('dropdown-menu')) {
            this.showLanguagesDropdown = false;
        }
    }

    /**
     * @function Constructor
     * @param {CustoService} _custoService
     * @param {Router} _router
     * @param {FormBuilder} _formBuilder
     * @param {I18nService} _i18nService
     * @param {AuthenticationService} _authenticationService
     * @param {TranslateService} _translateService
     * @param {GtuService} _gtuService
     * @param {FlashService} _flashService
     * @param {VersionService} _versionService
     * @param {ActivatedRoute} _route
     * @param {BrowserService} _browserService
     * @param {CustoService} _custoService
     */
    constructor(
        private _custoService: CustoService,
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _i18nService: I18nService,
        private _authenticationService: AuthenticationService,
        private _translateService: TranslateService,
        private _gtuService: GtuService,
        private _flashService: FlashService,
        private _versionService: VersionService,
        private _route: ActivatedRoute,
        private _browserService: BrowserService
    ) {
        this._detectIE11();
        this._initTranslations();
        this._createForm();
        this._displayVersion();
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this._route.queryParamMap.subscribe((params) => {
            this._token = params.get('token');
            this._action = params.get('action');
            this._uid = params.get('uid');
            this._forceDisclaimers = params.get('force');
        });

        /**
         * Dealing with external access such as join link from invitation email
         * For appropriate browsers, if token parameter exists and action parameter equals "join"
         * Then we automatically log the user into the app (V2/V3 tokens)
         */
        if (!this.isWrongBrowser() && this._token && this._action === TokenAction.JOIN && (!this._uid || (this._uid && this._uid !== ''))) {
            this.isExternalAccess = true;
        }

        // Initializing the different possible views
        this._initParams();
        this._initCusto(this.currentLanguage);

        // Listener for error messages sent by other components, especially when logging out
        this._listenToErrorMessages();
    }

    /**
     * @function _detectIE11
     * @description
     * @private
     * @returns {void}
     */
    private _detectIE11(): void {
        if (navigator && navigator.userAgent.indexOf('Trident/') > 0) {
            document.body.classList.add('ie11');
        }
    }

    /**
     * @function _shouldAutoValidateForm
     * @description
     * @private
     * @returns {void}
     */
    public _shouldAutoValidateForm(): void {
        if (this.isExternalAccess &&
            // If disclaimers by-passing is required
            (this._forceDisclaimers === 'true' ||
                (
                    // If none of the disclaimer and GRPD has a custom value
                    (!this.CUSTO['loginPageGrpdText'] && !this.CUSTO['loginPageDisclaimerText']) ||
                    // Or if there is no disclaimer custom value and if the GRPD has already been accepted
                    (!this.CUSTO['loginPageDisclaimerText'] && this.CUSTO['loginPageGrpdText'] && this._isGrpdAlreadyAccepted())
                )
            )
        ) {
            this.autoValidateForm();
        }
    }

    /**
     * @function autoValidateForm
     * @description
     * @public
     * @returns {void}
     */
    public autoValidateForm(): void {
        this._authenticationService.login({
            username: this._uid || null,
            password: this._token,
            remember: false
        })
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe(() => {
                this._router.navigate(['/'], {replaceUrl: true});
            }, error => {
                let message;
                message = this._translations['session_key_invalid'];
                this._flashService.error(message, 15000);
            });
    }

    ngAfterViewInit() {
      // prevent double scroll. scroll property on the IFrame GTU is necessary only in iphone|IPad
      const iframeWrapper = document.getElementById('iframeWrapper');
      if (iframeWrapper && (this._browserService.isIphone() || this._browserService.isIpad())) {
         iframeWrapper.style.overflowY = 'scroll';
      }
    }

    /**
     * @function _initParams
     * @description
     * @private
     * @returns {void}
     */
    private _initParams(): void {
        this._custoService.paramsLoadedSubject.subscribe((loaded: boolean) => {
            this.paramsLoaded = loaded;
            this.PARAMS['availableLocales'] = this._custoService.getParam('availableLocales');
            this.PARAMS['oceModeOnly'] = this._custoService.getParam('oceModeOnly');
            this.PARAMS['oceBasicStyle'] = this._custoService.getParam('oceBasicStyle');
            if (this.PARAMS['availableLocales'] && this.PARAMS['availableLocales'].length === 1) {
                this._i18nService.language = this.PARAMS['availableLocales'][0];
            }
        });
    }

    /**
     * @function _initCusto
     * @description
     * @private
     * @param {string} locale
     * @returns {void}
     */
    private _initCusto(locale: string): void {
        this._custoService.custoLoadedSubject.subscribe((loaded: boolean) => {
            this.custoLoaded = loaded;
            this._parseCusto(locale);

            // Auto-submit the form to log the user in automatically
            this._shouldAutoValidateForm();
        });
    }

    /**
     * @function _parseCusto
     * @description
     * @private
     * @param {string} locale
     * @returns {void}
     */
    private _parseCusto(locale: string): void {
        this.CUSTO['loginPageLogoUrl'] = this._custoService.getProp('loginPageLogoUrl', locale);
        this.CUSTO['loginPageLogoBackUrl'] = this._custoService.getProp('loginPageLogoBackUrl', locale);
        this.CUSTO['loginPageBackgroundColorMobile'] = this._custoService.getProp('loginPageBackgroundColorMobile', locale);
        this.CUSTO['loginPageBackgroundImageMobile'] = this._custoService.getProp('loginPageBackgroundImageMobile', locale);
        this.CUSTO['loginPageBackgroundPositionMobile'] = this._custoService.getProp('loginPageBackgroundPositionMobile', locale);
        this.CUSTO['loginPageBackgroundSizeMobile'] = this._custoService.getProp('loginPageBackgroundSizeMobile', locale);
        this.CUSTO['loginPageBackgroundColorDesktop'] = this._custoService.getProp('loginPageBackgroundColorDesktop', locale);
        this.CUSTO['loginPageBackgroundImageDesktop'] = this._custoService.getProp('loginPageBackgroundImageDesktop', locale);
        this.CUSTO['loginPageBackgroundPositionDesktop'] = this._custoService.getProp('loginPageBackgroundPositionDesktop', locale);
        this.CUSTO['loginPageBackgroundSizeDesktop'] = this._custoService.getProp('loginPageBackgroundSizeDesktop', locale);
        this.CUSTO['loginPageAddressText'] = this._custoService.getProp('loginPageAddressText', locale);
        this.CUSTO['loginPageDisclaimerText'] = this._custoService.getProp('loginPageDisclaimerText', locale);
        this.CUSTO['loginPageLegalBackgroundColorTheme'] = this._custoService.getProp('loginPageLegalBackgroundColorTheme', locale);
        this.CUSTO['loginPageButtonColor'] = this._custoService.getProp('loginPageButtonColor', locale);
        this.CUSTO['loginPageTextColorTheme'] = this._custoService.getProp('loginPageTextColorTheme', locale);
        this.CUSTO['loginPageGrpdTabName'] = this._custoService.getProp('loginPageGrpdTabName', locale);
        this.CUSTO['loginPageGrpdText'] = this._custoService.getProp('loginPageGrpdText', locale);

        // Initializing view when custo is loaded (even if there is no custo)
        this._initView();
    }

    /**
     * @function getGlobalClassnames
     * @description
     * @public
     * @returns {string}
     */
    public getGlobalClassnames(): string {
        let classNames: string = '';
        if (this.isExternalAccess) {
            classNames += 'externalAccessStyle';
        }
        if (this.CUSTO.loginPageTextColorTheme) {
            classNames += ' ' + this.CUSTO.loginPageTextColorTheme;
        }
        return classNames;
    }

    /**
     * @function getMainClassnames
     * @description
     * @public
     * @returns {string}
     */
    public getMainClassnames(): string {
        let classNames: string = '';
        const hasAddress: boolean = this.CUSTO['loginPageAddressText'];
        const hasJoinBtn: boolean = this.isExternalAccess && this.CUSTO['loginPageDisclaimerText'] && (!this.CUSTO['loginPageGrpdText'] || (this.CUSTO['loginPageGrpdText'] && this._isGrpdAlreadyAccepted()));
        if (hasAddress) {
            classNames = 'has-address';
        }
        if (hasJoinBtn) {
            classNames = 'has-join-btn';
        }
        if (hasAddress && hasJoinBtn) {
            classNames = 'has-address-and-join-btn';
        }
        if (this.PARAMS['oceBasicStyle']) {
            classNames += ' oce-basic-style';
        }
        return classNames;
    }

    /**
     * @function getBackgroundStyle
     * @description
     * @public
     * @returns {any}
     */
    public getBackgroundStyle(): any {
        const style: any = {};
        // Custo for mobile
        if (this._isMobileView()) {
            if (this.CUSTO['loginPageBackgroundColorMobile']) {
                style.backgroundColor = this.CUSTO['loginPageBackgroundColorMobile'];
            }
            // Fallback with desktop data if no mobile background-color available
            else if (this.CUSTO['loginPageBackgroundColorDesktop']) {
                style.backgroundColor = this.CUSTO['loginPageBackgroundColorDesktop'];
            }

            if (this.CUSTO['loginPageBackgroundImageMobile']) {
                style.backgroundImage = 'url(' + this.CUSTO['loginPageBackgroundImageMobile'] + ')';
                style.backgroundRepeat = 'no-repeat';
            }
            // Fallback with desktop data if no mobile background-image available
            else if (this.CUSTO['loginPageBackgroundImageDesktop']) {
                style.backgroundImage = 'url(' + this.CUSTO['loginPageBackgroundImageDesktop'] + ')';
                style.backgroundRepeat = 'no-repeat';
            }

            if (this.CUSTO['loginPageBackgroundPositionMobile']) {
                style.backgroundPosition = this.CUSTO['loginPageBackgroundPositionMobile'];
            }
            // Fallback with desktop data if no mobile background-position available
            else if (this.CUSTO['loginPageBackgroundPositionDesktop']) {
                style.backgroundPosition = this.CUSTO['loginPageBackgroundPositionDesktop'];
            }

            if (this.CUSTO['loginPageBackgroundSizeMobile']) {
                style.backgroundSize = this.CUSTO['loginPageBackgroundSizeMobile'];
            }
            // Fallback with desktop data if no mobile background-size available
            else if (this.CUSTO['loginPageBackgroundSizeDesktop']) {
                style.backgroundSize = this.CUSTO['loginPageBackgroundSizeDesktop'];
            }
        }
        // Custo for desktop
        else {
            if (this.CUSTO['loginPageBackgroundColorDesktop']) {
                style.backgroundColor = this.CUSTO['loginPageBackgroundColorDesktop'];
            }
            if (this.CUSTO['loginPageBackgroundImageDesktop']) {
                style.backgroundImage = 'url(' + this.CUSTO['loginPageBackgroundImageDesktop'] + ')';
                style.backgroundRepeat = 'no-repeat';
            }
            if (this.CUSTO['loginPageBackgroundPositionDesktop']) {
                style.backgroundPosition = this.CUSTO['loginPageBackgroundPositionDesktop'];
            }
            if (this.CUSTO['loginPageBackgroundSizeDesktop']) {
                style.backgroundSize = this.CUSTO['loginPageBackgroundSizeDesktop'];
            }
        }
        return style;
    }

    /**
     * @function getButtonStyle
     * @description
     * @public
     * @returns {any}
     */
    public getButtonStyle(): any {
        const style: any = {};
        style.borderColor = this.CUSTO['loginPageButtonColor'];
        style.backgroundColor = this.CUSTO['loginPageButtonColor'];
        return style;
    }

    /**
     * @function openInNewTab
     * @description
     * @public
     * @param {string} url
     * @return {void}
     */
    public openInNewTab(url: string = null): void {
        if (!url) { return; }
        window.open(url,'_blank');
    }

    /**
     * @function _displayVersion
     * @description
     * @private
     * @returns {void}
     */
    private _listenToErrorMessages(): void {
        this._authenticationService.errorSubject.subscribe((data: string) => {
            if (data === ErrorMessage.DUAL_LOGIN) {
                setTimeout(() => {
                    this._flashService.error(this._translations['already_connected'], 15000);
                    this._authenticationService.errorSubject.unsubscribe();
                }, 500);
            }
        });
    }

    /**
     * @function _displayVersion
     * @description
     * @private
     * @returns {void}
     */
    private _displayVersion() {
        this._versionService.getVersion()
            .subscribe((version: string) => {
                this.version = version;
                this._versionService.setVersion(version);
            });
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Username or password incorrect')
            .subscribe((trans: string) => this._translations['username_or_password_incorrect'] = trans);
        this._translateService.get('Session key invalid')
            .subscribe((trans: string) => this._translations['session_key_invalid'] = trans);
        this._translateService.get('Chrome')
            .subscribe((trans: string) => this._translations['chrome'] = trans);
        this._translateService.get('Google Chrome')
            .subscribe((trans: string) => this._translations['google_chrome'] = trans);
        this._translateService.get('Safari')
            .subscribe((trans: string) => this._translations['safari'] = trans);
        this._translateService.get('Apple Safari')
            .subscribe((trans: string) => this._translations['apple_safari'] = trans);
        this._translateService.get('Firefox')
            .subscribe((trans: string) => this._translations['firefox'] = trans);
        this._translateService.get('Mozilla Firefox')
            .subscribe((trans: string) => this._translations['mozilla_firefox'] = trans);
        this._translateService.get('Already connected')
            .subscribe((trans: string) => this._translations['already_connected'] = trans);
    }

    /**
     * @function _initView
     * @description
     * @private
     * @returns {void}
     */
    private _initView() {
        // Dealing with the displaying of the disclaimer
        // It should be displayed directly in desktop view but accessible by clicking the disclaimer button at the bottom in mobile view
        // Updating those different boolean values when the view is initializing or when it is resizing by the user. It has to adapt automatically

        // For mobile devices
        if (this._isMobileView()) {
            this.isDesktop = false;
            // Basic access
            if (!this.isExternalAccess) {
                // Hidding disclaimer modal
                this.isDisclaimerDisplayed = false;
                // Then hidding global legal container if GRPD has not to be displayed
                if (!this.CUSTO['loginPageGrpdText'] || (this.CUSTO['loginPageGrpdText'] && this._isGrpdAlreadyAccepted())) {
                    this.isLegalDisplayed = false;
                }
            }
            // External access
            else {
                // Showing disclaimer if custom loginPageDisclaimerText property is set
                this.displayDisclaimer();
            }
        }
        // For tablet/desktop devices
        else if (this._isDesktopView()) {
            this.isDesktop = true;
            // Showing disclaimer if custom loginPageDisclaimerText property is set
            this.displayDisclaimer();
        }

        this._displayGrpdIfNeeded();
    }

    /**
     * @function _isMobileView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isMobileView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return this._browserService.isMobile() || (!this._browserService.isMobile() && windowWidth <= 823);
    }

    /**
     * @function _isDesktopView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isDesktopView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return !this._browserService.isMobile() && windowWidth > 823;
    }

    /**
     * @function login
     * @description
     * @public
     * @returns {void}
     */
    public login(): void {
        this.isLoading = true;
        this._authenticationService
            .login(this.loginForm.value)
            .pipe(finalize(() => {
                this.loginForm.markAsPristine();
                this.isLoading = false;
            }))
            .subscribe(() => {
                log.debug(`Successfully authenticated`);
                this._checkGtuBeforeLogin();
            }, error => {
                log.debug(`Login error`, error);
                let message = '';
                if (error.error.error === 'USER_AND_PASSWORD_NOT_VALID') {
                    message = this._translations['username_or_password_incorrect'];
                }
                this._flashService.error(message, 15000);
            });
    }

    /**
     * @function _checkGtuBeforeLogin
     * @description
     * @private
     * @returns {void}
     */
    private _checkGtuBeforeLogin(): void {
        this._gtuService.get()
            .subscribe((gtu: Gtu) => {
                    //GTU are already accepted, navigating to main page
                    /* Setting condition to true to force GTU not to be displayed (Product Owners decision) */
                    if (gtu.status || true) {
                        log.debug(`Successfully logged in (GTU OK)`);
                        this._goToMainPage();
                    }
                    //GTU are not accepted yet, displaying a modal with GTU to accept
                    else {
                        log.debug(`GTU not accepted yet`);
                        this._displayGtuModal(gtu.url);
                    }
                }, error => {
                    log.debug(`GTU loading error: ${error}`);
                }
            );
    }

    /**
     * @function setLanguage
     * @description
     * @public
     * @param {any} $event
     * @param {string} language
     * @returns {void}
     */
    public setLanguage($event: any, language: string): void {
        $event.stopImmediatePropagation();
        this._i18nService.language = language;
        // Updating custo
        this._parseCusto(language);
        // Should we auto-validate the form with those new custom values?
        this._shouldAutoValidateForm();
        this.showLanguagesDropdown = false;
    }

    /**
     * @function currentLanguage
     * @description
     * @public
     * @returns {string}
     */
    public get currentLanguage(): string {
        return this._i18nService.language;
    }

    /**
     * @function languages
     * @description
     * @public
     * @returns {any}
     */
    public get languages(): string[] {
        return this._i18nService.supportedLanguages.filter((value) => {
            if (this.PARAMS['availableLocales']) {
                return this.PARAMS['availableLocales'].indexOf(value) !== -1;
            }
            return false;
        });
    }

    /**
     * @function getCountryCode
     * @description
     * @public
     * @param {string} LanguageCountryCode
     * @returns {string}
     */
    public getCountryCode(LanguageCountryCode: string): string {
        let countryCode = LanguageCountryCode.split('-').pop();
        if (LanguageCountryCode === 'zh-Hans') {
            countryCode = 'CN';
        }
        return countryCode;
    }

    /**
     * @function _createForm
     * @description
     * @private
     * @returns {void}
     */
    private _createForm(): void {
        this.loginForm = this._formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            remember: true
        });
    }

    /**
     * @function loadModalText
     * @description
     * @public
     * @returns {void}
     */
    public loadModalText(): void {
        if (this.modalTransToLoad) {
            return this._translateService.instant(this.modalTransToLoad);
        }
    }

    /**
     * @function displayRecoveryPwdModal
     * @description
     * @public
     * @returns {void}
     */
    public displayRecoveryPwdModal(): void {
        /* @TODO: remplace in template by displayRecoverPwdComponent() when new iAdmin API will be available */
        this.modalType = 'pwd';
        this.modalUrlToLoad = environment.resetPwdUrl;
        this.isModalDisplayed = true;
    }

    /**
     * @function displayDisclaimer
     * @description
     * @public
     * @returns {void}
     */
    public displayDisclaimer(): void {
        if (this.CUSTO['loginPageDisclaimerText']) {
            this.isLegalDisplayed = true;
            this.isDisclaimerDisplayed = true;
        } else {
            this.isDisclaimerDisplayed = false;
            // Hidding legal container if GRPD has not to be displayed
            if (!this.CUSTO['loginPageGrpdText']) {
                this.isLegalDisplayed = false;
            }
        }
    }

    /**
     * @function _displayGrpdIfNeeded
     * @description
     * @private
     * @returns {void}
     */
    public _displayGrpdIfNeeded(): void {
        if (this.CUSTO['loginPageGrpdText'] && !this._isGrpdAlreadyAccepted()) {
            this.isLegalDisplayed = true;
            this.isGrpdDisplayed = true;
        } else {
            this.isGrpdDisplayed = false;
            // Hidding legal container if disclaimer has not to be displayed
            if (!this.CUSTO['loginPageDisclaimerText']) {
                this.isLegalDisplayed = false;
            }
        }
    }

    /**
     * @function displayGrpdComponent
     * @description
     * @public
     * @returns {void}
     */
    public displayGrpdComponent(): void {
        this.isModalDisplayed = true;
        this.modalComponentToLoad = GrpdComponent;
    }

    /**
     * @function displayRecoverPwdComponent
     * @description
     * @public
     * @returns {void}
     */
    public displayRecoverPwdComponent(): void {
        this.isModalDisplayed = true;
        this.modalComponentToLoad = RecoverPwdComponent;
    }

    /**
     * @function _displayGtuModal
     * @description
     * @private
     * @param {string} url
     * @return {void}
     */
    private _displayGtuModal(url: string): void {
        this.modalType = 'gtu';
        this.modalUrlToLoad = url + '?access_token=' + this._authenticationService.credentials.access_token;
        this.isModalDisplayed = true;
    }

    /**
     * @function acceptGtu
     * @description
     * @public
     * @returns {void}
     */
    public acceptGtu(): void {
        this._gtuService.accept()
            .subscribe(() => {
                this._goToMainPage();
            });
    }

    /**
     * @function _declineGtu
     * @private
     * @description
     * @returns {void}
     */
    private _declineGtu(): void {
        this._authenticationService.logout()
            .subscribe(() => {});
    }

    /**
     * @function _goToMainPage
     * @private
     * @description
     * @returns {void}
     */
    private _goToMainPage(): void {
        this._router.navigate(['/'], { replaceUrl: true });
    }

    /**
     * @function closeModal
     * @description
     * @public
     * @returns {void}
     */
    public closeModal(): void {
        this.isModalDisplayed = false;
        if (this.modalUrlToLoad) { this.modalUrlToLoad = null; }
        if (this.modalTransToLoad) { this.modalTransToLoad = null; }
        if (this.modalComponentToLoad) { this.modalComponentToLoad = null; }
        //Logout the user if he has closed the GTU modal without accepting them,
        //as at this step, he has just been logged in
        if (this.modalType === 'gtu') {
            this._declineGtu();
            // Forcing footer to reappear
            // Onblur event on input fields seems not to work properly in some case
            this.showFooter();
        }
    }

    /**
     * @function isGtuModal
     * @description
     * @public
     * @returns {boolean}
     */
    public isGtuModal(): boolean {
        return this.modalType === 'gtu';
    }

    /**
     * @function setFullScreen
     * @description
     * @public
     * @returns {void}
     */
    public setFullScreen(): void {
        if (environment.env === 'local') {
            return;
        }
        const elem: any = document.documentElement;
        if (typeof elem.webkitRequestFullscreen !== 'undefined') {
            elem.webkitRequestFullscreen();
        } else if (typeof elem.mozRequestFullScreen !== 'undefined') {
            elem.mozRequestFullScreen();
        } else if (typeof elem.msRequestFullscreen !== 'undefined') {
            elem.msRequestFullscreen();
        } else if (typeof elem.requestFullscreen !== 'undefined') {
            elem.requestFullscreen();
        }
    }

    /**
     * @function isWrongBrowser
     * @description
     * @public
     * @returns {boolean}
     */
    public isWrongBrowser(): boolean {
        // if (environment.env === 'local') { return; }
        return (
            (this._browserService.isIos() && !this._browserService.isSafari()) // If not Safari on iOS devices
            || (this._browserService.isAndroid() && !this._browserService.isChrome()) // If not Chrome on Android devices
            || (!this._browserService.isDesktop() && this._browserService.isFirefox()) // If Firefox mobile
            || this._browserService.isOpera() // If Opera
        );
    }

    /**
     * @function browsers
     * @description
     * @public
     * @returns {string[]}
     */
    get browsers(): any[] {
        let browserNames: any[] = [];
        const chrome: any = { name: this._translations['chrome'], fullName: this._translations['google_chrome'], downloadLink: 'https://www.google.com/chrome/' };
        const firefox: any = { name: this._translations['firefox'], fullName: this._translations['mozilla_firefox'], downloadLink: 'https://www.mozilla.org/firefox/' };
        const safari: any = { name: this._translations['safari'], fullName: this._translations['apple_safari'], downloadLink: 'https://www.apple.com/safari/' };

        if (this._browserService.isAndroid()) {
            browserNames = [chrome];
        } else if (this._browserService.isIos()) {
            browserNames = [safari];
        } else if (this._browserService.isDesktop() && this._browserService.isMacOS()) {
            browserNames = [chrome, firefox, safari];
        } else if (this._browserService.isDesktop() && !this._browserService.isMacOS()) {
            browserNames = [chrome, firefox];
        }
        return browserNames;
    }

    /**
     * @function browsersNames
     * @description
     * @public
     * @returns {string}
     */
    get browsersNames() {
        return this.browsers.map(browser => browser.fullName).join(', ');
    }

    /**
     * @function showFooter
     * @description
     * @public
     * @returns {void}
     */
    public showFooter(): void {
        document.getElementsByTagName('footer')[0].classList.remove('hide-footer');
    }

    /**
     * @function hideFooter
     * @description
     * @public
     * @returns {void}
     */
    public hideFooter(): void {
        document.getElementsByTagName('footer')[0].classList.add('hide-footer');
    }

    /**
     * @function _isGrpdAlreadyAccepted
     * @description
     * @private
     * @returns {boolean}
     */
    private _isGrpdAlreadyAccepted(): boolean {
        const gdpr = localStorage.getItem('grpd');
        const parsedValue = gdpr ? JSON.parse(gdpr) : false;
        return parsedValue;
    }

    /**
     * @function acceptGrpd
     * @description
     * @public
     * @returns {void}
     */
    public acceptGrpd(): void {
        localStorage.setItem('grpd', 'true');
        this.isGrpdDisplayed = false;
        // For mobile devices and Desktop devices with a small width resolution, we hide the legal modal
        if ((this._browserService.isMobile() || (!this._browserService.isMobile() && document.body.clientWidth <= 823)) && !this.isExternalAccess) { // Pixel 2 XL's width resolution maximum
            this.isLegalDisplayed = false;
        }
        // Validating the form to log the user in automatically
        if (this.isExternalAccess) {
            this.autoValidateForm();
            this.setFullScreen();
        }
    }

    /**
     * @function displayJoinBtn
     * @description
     * @public
     * @returns {boolean}
     */
    public displayJoinBtn(): boolean {
        // Displaying Join button only when disclaimer is displayed (a custom GRPD text does not exist OR a custom GRPD text exists but already accepted)
        return this.isExternalAccess && this.CUSTO['loginPageDisclaimerText'] && (!this.CUSTO['loginPageGrpdText'] || (this.CUSTO['loginPageGrpdText'] && this._isGrpdAlreadyAccepted()));
    }

    /**
     * @function copyUrlToClipboard
     * @description
     * @public
     * @returns {void}
     */
    public copyUrlToClipboard(): void {
        const url = window.location.href;
        const nav: any = navigator;
        if (nav && nav.clipboard) {
            nav.clipboard.writeText(url).then(
                () => { console.log(url); },
                (err: any) => {}
            );
        }
    }
}
